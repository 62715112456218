import React from 'react'

import { Layout } from '../components/layout'

import { Footer } from '../components/footer'
import { Teaser } from '../components/teaser'

const title = '404'
const description =
  'Die Seite wurde nicht gefunden.'
const keywords = ''

const NotFoundPage = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="handel" />

    <Footer />
  </Layout>
)

export default NotFoundPage
